import { graphql } from 'gatsby';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import SimilarPostsContainer from '../components/containers/SimilarPostsContainer'
import Layout from '../components/Layout';
import Link from '../components/Link';
import Seo from '../components/SEO';
import { usePageContext } from '../pageContext';
import { Articles } from '../providers/Articles';

function ArticleTemplate({ data }) {


    console.log(data);
    const { article, dataS } = data;

    const articleDetails = Articles.find(a => a.slug == article.slug);
    const currentIndex = Articles.findIndex(a => a.slug == article.slug);

    let prev = null;
    let next = null;

    if (currentIndex < Articles.length - 1) {
        next = Articles[currentIndex + 1];
    }

    if (currentIndex > 0) {
        prev = Articles[currentIndex - 1];
    }

    const ContentComponent = articleDetails.Component;

    const languageData = useTranslation()[1].store.data;

    const { lang } = usePageContext();
  
    const echo = (expression) => {
      return languageData[lang]['articleTemplate'][expression];
    }

    return (
        <Fragment>

            <Layout data={{ site: dataS }} lang={lang}>

                <Seo lang='fr' title={`${articleDetails.title} | Hanniel TSASSE`} description={articleDetails.description} image={articleDetails.image} />

                {/* <!-- container --> */}
                <div className="container-fluid">

                    {/* <!-- row --> */}
                    <div className="row p-30-0">

                        {/* <!-- col --> */}
                        <div className="col-lg-12">

                            {/* <!-- section title --> */}
                            <div className="art-section-title">
                                {/* <!-- title frame --> */}
                                <div className="art-title-frame">
                                    {/* <!-- title --> */}
                                    <h1>{articleDetails.title}</h1>
                                </div>
                                {/* <!-- title frame end --> */}
                                {/* <!-- right frame --> */}
                                <div className="art-right-frame">
                                    <div className="art-project-category">{articleDetails.category}</div>
                                </div>
                                {/* <!-- right frame end --> */}
                            </div>
                            {/* <!-- section title end --> */}

                        </div>
                        {/* <!-- col end --> */}

                        {/* <!-- col --> */}
                        <div className="col-lg-12">

                            {/* <!-- project cover --> */}
                            <div className="art-a art-project-cover">
                                {/* <!-- item frame --> */}
                                <a data-fancybox="gallery" href={"/" + articleDetails.image} className="art-portfolio-item-frame art-horizontal">
                                    {/* <!-- img --> */}
                                    <img src={"/" + articleDetails.image} alt="item" />
                                    {/* <!-- zoom icon --> */}
                                    <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                </a>
                                {/* <!-- item end --> */}
                            </div>
                            {/* <!-- project cover nd --> */}

                        </div>
                        {/* <!-- col end --> */}

                        <ContentComponent />

                        {/* <!-- col --> */}
                        {/* <div className="col-lg-4">
                            <div className="art-a art-card">
                                <div className="art-table p-15-15">
                                    <ul>
                                        <li>
                                            <h6>Date:</h6><span>24.12.2019</span>
                                        </li>
                                        <li>
                                            <h6>Author:</h6><span>Hanniel TSASSE</span>
                                        </li>
                                        <li>
                                            <h6>Category:</h6><span>Product design</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        {/* <!-- col end --> */}

                    </div>
                    {/* <!-- row end --> */}

                </div>
                {/* <!-- container end --> */}

                {/* <!-- container --> */}
                <div className="container-fluid">

                    {/* <!-- row --> */}
                    <div className="row">

                        {/* <!-- col --> */}
                        <div className="col-lg-12">

                            {/* <!-- pagination --> */}
                            <div className="art-a art-pagination">

                                <Link to={`/articles/${prev != null ? prev.slug : articleDetails.slug}`} className="art-link art-color-link art-w-chevron art-left-link"><span>{lang == 'fr' ? 'Article précédent' : 'Previous post'}</span></Link>

                                <div className="art-pagination-center art-m-hidden">
                                    <Link className="art-link" to="/articles">{lang == 'fr' ? 'Tous les articles' : 'All posts'}</Link>
                                </div>

                                <Link to={`/articles/${next != null ? next.slug : articleDetails.slug}`} className="art-link art-color-link art-w-chevron"><span>{lang == 'fr' ? 'Article suivant' : 'Next post'}</span></Link>

                            </div>
                            {/* <!-- pagination end --> */}

                        </div>
                        {/* <!-- col end --> */}

                    </div>
                    {/* <!-- row end --> */}

                </div>
                {/* <!-- container end --> */}

                {/* <!-- container --> */}
                <SimilarPostsContainer />
                {/* <!-- container end --> */}
            </Layout>

        </Fragment >
    )
}

export default ArticleTemplate


export const query = graphql`
  query ArticlePage($slug: String!) {
    article: articlesJson(slug: { eq: $slug }) {
        slug
    }

    dataS: site {
      siteMetadata {
        siteURL
      }
    }
  }
`